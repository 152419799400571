<script lang="ts">
  import { createEventDispatcher } from "svelte";

  const dispatch = createEventDispatcher();

  export let sidebar = true;
  export let closable = false;
  export let disabled = false;
  export let is_link = false;
  export let title: string = null;
  export let single_pane = false;

  function close() {
    dispatch("close");
  }
</script>

<div class:single-pane={single_pane}>
  <div
    class="panel"
    class:is-primary={sidebar}
    class:is-info={!sidebar}
    class:is-link={is_link && sidebar}
    class:disabled
  >
    <p
      class="panel-heading this-heading is-flex is-justify-content-space-between"
    >
      {#if $$slots.title}
        <slot name="title" />
      {:else}
        {title}
      {/if}
      {#if closable}
        <div>
          <button class="delete" on:click={close} />
        </div>
      {/if}
    </p>
    <slot />
  </div>
</div>

<style lang="scss">
  .single-pane {
    margin: 0 auto;
    margin-top: 3vh;
    max-width: 960px;
    min-height: 90vh;
  }
  .panel {
    background: white;
  }

  .this-heading {
    margin-bottom: 0 !important;
  }
  .this-heading :global(.panel-icon) {
    color: white;
  }
</style>
